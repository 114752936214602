/* ======================================================================== */
/* 9. PJAX Transition Halfscreen Slider */
/* ======================================================================== */
var PJAXTransitionHalfscreenSlider = {
	name: 'halfscreenSlider',
	custom: ({
		current,
		next,
		trigger
	}) => {
		return $(trigger).data('pjax-link') == 'halfscreenSlider';
	},
	before: (data) => {

		return new Promise(function (resolve, reject) {

			PJAXPrepareTransition(data).then(function () {
				resolve(true);
			});

		});

	},
	beforeLeave: (data) => {

		return new Promise(function (resolve, reject) {

			var
				tl = new TimelineMax(),
				$sliderContent = $('.slider-halfscreen__content'),
				$wrapperImg = $('.slider-halfscreen__images-slide.swiper-slide-active .slider-halfscreen__images-slide-inner'),
				$mobileOverlay = $('.slider-halfscreen__overlay'),
				$customPositionElement = $('.slider-halfscreen__images-slide.swiper-slide-active .slider-halfscreen__bg');

			tl
				.to($wrapperImg, 0.6, {
					scale: 1
				})
				.to($sliderContent, 1.2, {
					x: '10vw',
					autoAlpha: 0,
					ease: Expo.easeInOut
				}, '0.6')
				.to($mobileOverlay, 0.6, {
					autoAlpha: 0
				}, '0')
				.set(window.$wrapperBackgrounds, {
					zIndex: 400,
					scaleX: 1,
					autoAlpha: 1,
					backgroundColor: 'transparent',
				})
				.set(window.$backgroundsOverlay, {
					autoAlpha: 0
				})
				.add(function () {
					PJAXAnimateClonnedImage(data, $customPositionElement).then(function () {
						resolve(true);
					});
				}, '0.6');

		});

	},
	enter: (data) => {

		return new Promise(function (resolve, reject) {

			PJAXInitNewPage(data).then(function () {
				resolve(true)
			});

		});

	},
	afterEnter: (data) => {

		return new Promise(function (resolve, reject) {

			PJAXAnimateMasthead(data).then(function () {
				resolve(true);
			});

		});

	},
	after: (data) => {

		return new Promise(function (resolve, reject) {

			PJAXFinishLoading(data).then(function () {
				resolve(true);
			});

		});

	}
}
