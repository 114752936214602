/* ======================================================================== */
/* 2. PJAX Animate Clonned Image */
/* ======================================================================== */
function PJAXAnimateClonnedImage(data, $customPositionElement) {

	return new Promise(function (resolve, reject) {
		var
			tl = new TimelineMax(),
			$trigger = $(data.trigger),
			$postId = $trigger.data('post-id'),
			$targetBackground = window.$backgrounds.filter('[data-background-for=' + $postId + ']'),
			$img = $customPositionElement ? $customPositionElement : $trigger.find('img[src]');

		if (!$img.length) {
			resolve(true);
		}

		var
			$clone = $img.clone(),
			imgPosition = $img.get(0).getBoundingClientRect();

		tl.timeScale(1.25);

		tl
			.set($clone, {
				position: 'fixed',
				top: imgPosition.top,
				left: imgPosition.left,
				width: imgPosition.width,
				height: imgPosition.height,
				className: '+=of-cover',
				zIndex: 300
			})
			.add(function () {
				$targetBackground.addClass('selected');
				$clone.appendTo(window.$body);
			})
			.set(window.$backgroundsOverlay, {
				autoAlpha: 0
			})
			.to($clone, 1.2, {
				transition: 'none',
				transform: 'none',
				top: 0,
				left: 0,
				width: '100%',
				height: '100%',
				ease: Expo.easeInOut
			})
			.set(window.$wrapperBackgrounds, {
				backgroundColor: 'transparent',
				scaleX: 1,
				zIndex: 400,
				autoAlpha: 1
			})
			.add(function () {

				scrollToVeryTop();
				setTimeout(function () {
					$clone.remove();
					resolve(true);
				}, 100);

			});

	});

}
