/* ======================================================================== */
/* 6. PJAX Prepare Transition */
/* ======================================================================== */
function PJAXPrepareTransition(data) {

	return new Promise(function (resolve, reject) {

		var $trigger = $(data.trigger);

		window.InteractiveCursor.drawLoading();
		window.$overlay.addClass('intransition lockhover');
		$trigger.addClass('selected');

		TweenMax.set(window.$curtains, {
			scaleX: 0,
			transformOrigin: 'left center'
		});

		TweenMax.set(window.$preloader, {
			autoAlpha: 1
		});

		if (window.$spinner.length) {
			TweenMax.to(window.$spinner, 0.6, {
				autoAlpha: 1
			});
		}

		resolve(true);

	});

}
