/* ======================================================================== */
/* 21. grid */
/* ======================================================================== */
var Grid = function ($grid = $('.js-grid')) {

	if (!$grid.length) {
		return;
	}

	this.masonryGrid = $grid.masonry({
		itemSelector: '.js-grid__item',
		columnWidth: '.js-grid__sizer',
		percentPosition: true
	});

}
