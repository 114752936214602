/* ======================================================================== */
/* 45. SliderHeadings */
/* ======================================================================== */
var SliderHeadings = function ($slider) {

	if (!$slider.length) {
		return;
	}

	var
		$heading = $slider.find('h2'),
		tl = new TimelineMax(),
		slider = new Swiper($slider, {
			simulateTouch: false,
			effect: 'fade',
			fadeEffect: {
				crossFade: true
			},
			speed: 1200,
			centeredSlides: true,
			mousewheel: {
				eventsTarged: '.page-wrapper',
				releaseOnEdges: true,
			},
			pagination: {
				el: '.js-slider-headings__dots',
				type: 'bullets',
				bulletElement: 'div',
				clickable: true,
				bulletClass: 'slider__dot',
				bulletActiveClass: 'slider__dot_active'
			},
			navigation: {
				nextEl: '.js-slider-headings__next',
				prevEl: '.js-slider-headings__prev',
			},
			breakpoints: {
				768: {
					slidesPerView: 1,
					centeredSlides: true,
				}
			}
		});

	slider
		.on('slideNextTransitionStart', function () {

			var
				$activeSlide = $(slider.slides[slider.activeIndex]),
				$activeHeading = $activeSlide.find($heading);

			tl.clear();

			$heading.each(function () {
				tl
					.add(hideWords($(this), 0.3, 0.02, '50px', true), '0')
					.add(hideWords($(this), '0', '0', '-50px'))
			});

			tl.add(animateWords($activeHeading, 1.2, 0.02, true));

		})
		.on('slidePrevTransitionStart', function () {

			var
				$activeSlide = $(slider.slides[slider.activeIndex]),
				$activeHeading = $activeSlide.find($heading);

			tl.clear();

			$heading.each(function () {
				tl
					.add(hideWords($(this), 0.3, 0.02, '-50px', false), '0')
					.add(hideWords($(this), '0', '0', '50px'))
			});

			tl.add(animateWords($activeHeading, 1.2, 0.02, false));

		});

	renderSliderCounter(
		slider,
		$slider.find('.js-slider-headings-counter-current'),
		'slider-headings__counter-number',
		''
	);

}
