/* ======================================================================== */
/* 5. PJAX Init New Page */
/* ======================================================================== */
function PJAXInitNewPage(data) {

	return new Promise(function (resolve, reject) {

		var
			tl = new TimelineMax(),
			$nextContainer = $(data.next.container);

		tl
			.add(function () {

				// clear & re-init ScrollMagic
				window.SMController.destroy(true);
				window.SMController = new ScrollMagic.Controller();

				// split text lines in new container
				doSplitText($nextContainer).then(setLines($nextContainer)).then(function () {

					// scroll to the top
					setTimeout(function () {
						scrollToVeryTop();
					}, 100);

					// re-init components
					initComponents($nextContainer);
					window.SMController.enabled(false);

					// update ad trackers
					PJAXUpdateTrackers();

				});

				// change header color if needed
				switch (data.next.namespace) {
					case 'light': {
						window.$header.removeClass('header_white').addClass('header_black');
						break;
					}
					case 'dark': {
						window.$header.removeClass('header_black').addClass('header_white');
						break;
					}
				}

			})
			.add(function () {
				resolve(true);
			});

	});

}
