/* ======================================================================== */
/* 12. PJAX Transition Overlay Menu */
/* ======================================================================== */
var PJAXTransitionOverlayMenu = {
	name: 'overlayMenu',
	custom: ({
		current,
		next,
		trigger
	}) => {
		return $(trigger).data('pjax-link') == 'overlayMenu';
	},

	before: (data) => {

		return new Promise(function (resolve, reject) {

			PJAXPrepareTransition(data).then(function () {
				lockScroll(false);
				resolve(true);
			});

		});

	},

	enter: (data) => {

		return new Promise(function (resolve, reject) {

			PJAXInitNewPage(data).then(function () {

				var
					tl = new TimelineMax(),
					$nextContainer = $(data.next.container),
					$nextMasthead = $nextContainer.find('.section-masthead').first(),
					$nextBg = $nextMasthead.find('.section-masthead__background .art-parallax__bg'),
					tlClose = $nextBg.length ? window.PageHeader.hideOverlayMenu(false) : window.PageHeader.hideOverlayMenu(true),
					$nextOverlay = $nextMasthead.find('.section-masthead__overlay');

				if ($nextBg.length) {

					if ($nextOverlay.length) {

						tl.to(window.$backgroundsOverlay, 1.2, {
							autoAlpha: 0.6,
							transition: 'none',
							ease: Expo.easeInOut,
						});

					} else {

						tl.to(window.$backgroundsOverlay, 1.2, {
							autoAlpha: 0,
							transition: 'none',
							ease: Expo.easeInOut,
						});

					}

				}

				tl
					.add(tlClose, '0')
					// .add(function () {
					// 	lockScroll(false);
					// }, '0.4')
					.to($nextContainer, 1.2, {
						x: '0vw',
						force3D: true,
						ease: Expo.easeInOut
					}, '0.4')
					.set(window.$preloader, {
						autoAlpha: 0
					})
					.set(window.$overlay, {
						className: '+=intransition'
					})
					.set(window.$wrapperBackgrounds, {
						backgroundColor: 'transparent'
					})
					.add(function () {
						resolve(true);
					});

			});

		});

	},

	afterEnter: (data) => {

		return new Promise(function (resolve, reject) {

			PJAXAnimateMasthead(data).then(function () {
				resolve(true);
			});

		});

	},
	after: (data) => {

		return new Promise(function (resolve, reject) {

			PJAXFinishLoading(data).then(function () {
				resolve(true);
			});

		});

	}
}
