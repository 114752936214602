/* ======================================================================== */
/* 7. PJAX Transition Fullscreen Slider */
/* ======================================================================== */
var PJAXTransitionFullscreenSlider = {
	name: 'fullscreenSlider',
	custom: ({
		current,
		next,
		trigger
	}) => {
		return $(trigger).data('pjax-link') == 'fullscreenSlider';
	},
	before: (data) => {

		return new Promise(function (resolve, reject) {

			PJAXPrepareTransition(data).then(function () {
				resolve(true);
			});

		});

	},
	beforeLeave: (data) => {

		return new Promise(function (resolve, reject) {

			var
				tl = new TimelineMax(),
				$target = $('.section-fullscreen-slider'),
				$backgroundOverlay = $target.find('.slider__background-overlay');

			tl
				.set(window.$wrapperBackgrounds, {
					zIndex: -1,
					scaleX: 1,
					autoAlpha: 1,
					backgroundColor: 'transparent',
				})
				.set(window.$backgroundsOverlay, {
					autoAlpha: 0
				})
				.to($backgroundOverlay, 1.2, {
					autoAlpha: 0,
					ease: Expo.easeInOut
				}, '0.6')
				.to($target, 1.2, {
					autoAlpha: 0,
					ease: Expo.easeInOut
				}, '0')
				.to(window.$wrapperBackgrounds, 1.2, {
					autoAlpha: 1,
					zIndex: 400,
					ease: Expo.easeInOut
				}, '0.6')
				.add(function () {
					resolve(true);
				});

		});

	},
	enter: (data) => {

		return new Promise(function (resolve, reject) {

			PJAXInitNewPage(data).then(function () {
				resolve(true)
			});

		});

	},
	afterEnter: (data) => {

		return new Promise(function (resolve, reject) {

			PJAXAnimateMasthead(data).then(function () {
				resolve(true);
			});

		});

	},
	after: (data) => {

		return new Promise(function (resolve, reject) {

			PJAXFinishLoading(data).then(function () {
				resolve(true);
			});

		});

	}
}
