/* ======================================================================== */
/* 4. PJAX Finish Loading */
/* ======================================================================== */
function PJAXFinishLoading(data) {

	return new Promise(function (resolve, reject) {

		window.SMController.enabled(true);
		window.SMController.update(true);
		window.$backgrounds.removeClass('selected active');
		window.$header.removeClass('header_lock-submenus');
		window.InteractiveCursor.finishLoading();

		lockScroll(false);

		TweenMax.to(window.$spinner, 1.2, {
			autoAlpha: 0
		});

		TweenMax.set(window.$preloader, {
			autoAlpha: 0
		});

		TweenMax.set(window.$wrapperBackgrounds, {
			autoAlpha: 0,
			zIndex: -1,
			clearProps: 'width,height,left,right,top,bottom,backgroundColor',
		});

		TweenMax.set(window.$backgroundsOverlay, {
			autoAlpha: 1
		});

		TweenMax.set(window.$backgrounds, {
			transition: '',
			clearProps: 'transform,width,height',
		});

		setTimeout(function () {
			window.$overlay.removeClass('intransition lockhover opened');
		}, 300);

		resolve(true);

	});

}
